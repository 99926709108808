@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,700');

* {
  box-sizing: border-box;
}

body {
  font-family: Nunito,sans-serif;
  font-size: 16px;
  overflow: hidden;
}
